<!-- eslint-disable no-console -->
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import api from "../../../common/axios";

/**
 * Blank page component
 */
export default {
  page: {
    title: "Change Password",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      config: {
        api: "profile/change_password",
        redirect: "profile",
      },
      title: "Ubah Password",
      authError: null,
      isAuthError: false,
      model: {
        new_password: null,
        password_confirmation: null,
        old_password: null,
      },
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Ubah Password",
          href: "/change-profile",
          active: true,
        },
      ],
    };
  },
  methods: {
    updtaePassword() {
      let _ = this;
      api
        .put(_.config.api, _.model)
        .then(() => {
          _.$toast("Edit Password Success", {
            icon: "fas fa-check-circle",
            type: "success",
          });
          setTimeout(() => {
            _.$router.push({
              name: _.config.redirect,
            });
          }, 2000);
        })
        .catch((e) => {
          this.authError = e ? e.response.data.message : "";
          this.isAuthError = true;
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6 mt-4">
                <b-alert
                  v-model="isAuthError"
                  variant="danger"
                  class="mt-3"
                  dismissible
                  >{{ authError }}</b-alert
                >
                <ValidationObserver v-slot="{ handleSubmit }">
                  <b-form
                    @submit.prevent="handleSubmit(updtaePassword)"
                    class="form-horizontal"
                  >
                    <ValidationProvider
                      name="Old Password"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-group
                        id="input-group-2"
                        label="Password Lama"
                        label-for="input-2"
                      >
                        <b-form-input
                          id="input-2"
                          v-model="model.old_password"
                          type="password"
                        ></b-form-input>
                        <p class="fs-6 text-danger">
                          {{ errors[0] }}
                        </p>
                      </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider
                      name="New Password"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-group
                        id="input-group-2"
                        label="Password Baru"
                        label-for="input-2"
                      >
                        <b-form-input
                          id="input-2"
                          v-model="model.new_password"
                          type="password"
                        ></b-form-input>
                        <p class="fs-6 text-danger">
                          {{ errors[0] }}
                        </p>
                      </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider
                      name="Confirmation Password"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-group
                        id="input-group-2"
                        label="Konfirmasi Password Baru"
                        label-for="input-2"
                      >
                        <b-form-input
                          id="input-2"
                          v-model="model.password_confirmation"
                          type="password"
                        ></b-form-input>
                        <p class="fs-6 text-danger">
                          {{ errors[0] }}
                        </p>
                      </b-form-group>
                    </ValidationProvider>

                    <div class="form-group row">
                      <div class="col-sm-4">
                        <b-button
                          type="button"
                          to="/profile"
                          variant="light"
                          class="w-100"
                          >Kembali</b-button
                        >
                      </div>

                      <div class="col-sm-3">
                        <b-button type="submit" variant="primary" class="w-100"
                          >Simpan</b-button
                        >
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
